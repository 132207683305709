@font-face {
    font-family: 'lmroman';
    src: url('lmroman10-regular-webfont.eot');
    src: url('lmroman10-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('lmroman10-regular-webfont.woff') format('woff'),
         url('lmroman10-regular-webfont.ttf') format('truetype'),
         url('lmroman10-regular-webfont.svg#latin_modern_roman10_regular') format('svg');
    font-weight: normal;
    font-style: normal;

}
