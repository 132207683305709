.lmroman { font-family: lmroman, serif; }
.twitter-tweet > p {
    @extend .avenir;
    @extend .bb;
    @extend .b--silver;
    @extend .bg-lightest-blue;
    @extend .br4;
    @extend .br--top;
    @extend .pa4;
}
img {
    max-width: 100%;
    max-height: 100%;
}
@import "rfinz/lmroman.scss", "../node_modules/tachyons-sass/tachyons.scss";
